import { TrpcBase } from '../../utils/trpc';

import { useCallback, useMemo } from 'react';

export function useBuildingConfig() {
	const { data, isFetchedAfterMount } =
		TrpcBase.apartment.getBuildingsConfig.useQuery();
	const { mutateAsync } = TrpcBase.apartment.saveCurrentBuilding.useMutation();
	const currentBuildingConfigOrigin = useMemo(() => {
		return data?.buildings.find(
			(i) => i.buildingId === data.currentSelectedBuilding,
		);
	}, [data]);

	const changeTitle = useCallback(
		(name: string) => {
			const newName = `${currentBuildingConfigOrigin?.buildingName} ${name}`;

			if (document.title === newName) {
				return;
			}

			document.title = newName;
		},
		[currentBuildingConfigOrigin],
	);

	return {
		data,
		mutateAsync,
		currentSelectedBuildingId: data?.currentSelectedBuilding,
		isDone: !isFetchedAfterMount,
		currentBuildingConfig: currentBuildingConfigOrigin,
		changeTitle,
	};
}
